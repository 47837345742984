import React, { FC } from 'react';
import { Element } from 'react-scroll';
import { INSPECT_ROUTE, SENTIMENTS_ROUTE, UPTIME_ROUTE, WEB_ANALYTICS_ROUTE } from '../../AppRouter';

import WebAnalyticsImage from '../../assets/images/analytics-image.svg';
import InspectImage from '../../assets/images/inspect-image.svg';
import WebAnalyticsIcon from '../../assets/images/quicknav-svg/analytics-icon.svg';
import InspectIcon from '../../assets/images/quicknav-svg/inspect-icon.svg';
import SentimentsIcon from '../../assets/images/quicknav-svg/sentiments-icon.svg';
import UptimeIcon from '../../assets/images/quicknav-svg/uptime-icon.svg';
import SentimentsImage from '../../assets/images/sentiments-image.svg';
import UptimeImage from '../../assets/images/uptime-image.svg';
import SvgBulb from '../../icons/SvgBulb';

import Button, { ButtonVariant } from '../Button';
import FlexContainer from '../FlexContainer';
import ImageResponsive from '../ImageResponsive';
import InsightCard from '../InsightCard';
import LayoutContainer from '../LayoutContainer';
import OffsetCard from '../OffsetCard';
import Paragraph from '../Paragraph';
import ServiceButton from '../ServiceButton';
import { ServiceLayout, ServiceLayoutBlock } from '../ServiceLayout';
import SignUpMountain from '../SignUpMountain';
import SubTitle from '../SubTitle';
import TitleSeparator from '../TitleSeparator';

import style from './style.module.scss';

const OurServices: FC<{}> = () => {
  return (
    <section>
      <LayoutContainer className="px-4 mt-20" style={{maxWidth:1280}}>
        <TitleSeparator name="Our Services"/>
        <div className="flex justify-center items-center">
          <div className="w-full md-w-1/2">
            <div className="flex flex-wrap -mx-2">
              <ServiceButton anchor="section-wogaa-analytics" image={WebAnalyticsIcon} name="Web Analytics"/>
              <ServiceButton anchor="section-wogaa-uptime" image={UptimeIcon} name="Uptime"/>
              <ServiceButton anchor="section-wogaa-inspect" image={InspectIcon} name="Inspect"/>
              <ServiceButton anchor="section-wogaa-sentiments" image={SentimentsIcon} name="Sentiments"/>
            </div>
          </div>
        </div>
      </LayoutContainer>

      <Element name="section-wogaa-analytics">
        <div className={`bg-info mt-20 ${style.ServicesSvg} ${style.NarrowTopRightAfter}`}>
          <LayoutContainer className="px-4" style={{maxWidth:1280}}>
            <OffsetCard>With over <span
              className="text-primary-dark font-bold">100 million</span> visits to Government sites
              monthly,<br/> Our data-driven insights help <span className="text-primary-dark font-bold">99 government
                agencies</span> make better informed decisions.
            </OffsetCard>
            <div className="flex flex-wrap">
              <ServiceLayoutBlock>
                <div>
                  <Paragraph className="font-bold text-lg">Web Analytics</Paragraph>
                  <SubTitle className="text-primary-dark mt-3">Want to find out how users are interacting with your
                    sites?</SubTitle>

                  <FlexContainer justifyContent="center" alignItems="center">
                    <ImageResponsive
                      className="block sm-hidden"
                      lazy={true}
                      width={250}
                      src={WebAnalyticsImage}
                      alt="web-analytics"/>
                  </FlexContainer>

                  <Paragraph className="mt-3">Analyse how your sites and services are performing with key metrics
                    through an intuitive dashboard.</Paragraph>

                  <div className="flex mt-5 -mx-2">
                    <InsightCard>
                      <FlexContainer>
                        <div className="w-2/12">
                          <SvgBulb/>
                        </div>
                        <div className="w-10/12">
                          <Paragraph className="font-bold">Average visit duration</Paragraph>
                        </div>
                      </FlexContainer>
                      <FlexContainer>
                        <div className="ml-ratio w-10/12">
                          <SubTitle>4m</SubTitle>
                        </div>
                      </FlexContainer>
                    </InsightCard>
                    <InsightCard>
                      <FlexContainer>
                        <div className="w-2/12">
                          <SvgBulb/>
                        </div>
                        <div className="w-10/12">
                          <Paragraph className="font-bold">Average page views per visit</Paragraph>
                        </div>
                      </FlexContainer>
                      <FlexContainer>
                        <div className="ml-ratio w-10/12">
                          <SubTitle>3.8</SubTitle>
                        </div>
                      </FlexContainer>
                    </InsightCard>
                  </div>

                  <FlexContainer justifyContent="center" alignItems="center" className="md-block mt-5">
                    <Button to={WEB_ANALYTICS_ROUTE} size="sm" variant={ButtonVariant.PrimaryOutlineWhite}>LEARN
                      MORE</Button>
                  </FlexContainer>
                </div>
              </ServiceLayoutBlock>
              <ServiceLayout>
                <ImageResponsive
                  lazy={true}
                  width={450}
                  src={WebAnalyticsImage}
                  alt="web-analytics"/>
              </ServiceLayout>
            </div>
          </LayoutContainer>
        </div>
      </Element>
      <Element name="section-wogaa-uptime">
        <div className={`mt-20 xl-pt-32 ${style.ServicesSvg} ${style.NarrowTopLeftAfter}`}>
          <LayoutContainer className="px-4 py-2" style={{maxWidth:1280}}>
            <div className="flex flex-wrap">
              <ServiceLayout>
                <ImageResponsive lazy={true}
                                 width={450}
                                 src={UptimeImage}
                                 alt="uptime"/>
              </ServiceLayout>
              <ServiceLayoutBlock>
                <div>
                  <Paragraph className="font-bold text-lg">Uptime</Paragraph>
                  <SubTitle className="text-primary-dark mt-3">Wonder when your site goes down?</SubTitle>
                  <FlexContainer justifyContent="center" alignItems="center">
                    <ImageResponsive
                      className="block sm-hidden"
                      lazy={true}
                      width={450}
                      src={UptimeImage}
                      alt="uptime"/>
                  </FlexContainer>
                  <div className="w-full lg-w-10/12 md-w-full">
                    <Paragraph className="mt-3">Monitor the day-to-day availability of your sites and
                      get alerted when they are down.</Paragraph>
                  </div>

                  <div className="flex mt-5 -mx-2">
                    <InsightCard fullWidth={true}>
                      <FlexContainer>
                        <div className="w-1/12">
                          <SvgBulb/>
                        </div>
                        <div className="w-full lg-w-8/12 md-w-full">
                          <Paragraph>Having <span className="text-2xl font-bold">99.5%</span> Uptime means
                            users are unable to access your site for <span
                              className="text-2xl font-bold">43.75</span> hours a year.</Paragraph>
                        </div>
                      </FlexContainer>
                    </InsightCard>
                  </div>

                  <FlexContainer justifyContent="center" alignItems="center" className="md-block mt-5">
                    <Button to={UPTIME_ROUTE} size="sm" variant={ButtonVariant.PrimaryOutlineWhite}>LEARN MORE</Button>
                  </FlexContainer>
                </div>
              </ServiceLayoutBlock>
            </div>
          </LayoutContainer>
        </div>
      </Element>
      <Element name="section-wogaa-inspect">
        <div className={`bg-info ${style.ServicesSvg} ${style.NarrowTopLeftBlueAfter} pt-10 md-pt-32`}>
          <LayoutContainer className="px-4 py-10 md-py-20" style={{maxWidth:1280}}>
            <div className="flex flex-wrap">
              <ServiceLayoutBlock>
                <div>
                  <Paragraph className="font-bold text-lg">Inspect</Paragraph>
                  <SubTitle className="text-primary-dark mt-3">Interested to know how to improve your site?</SubTitle>
                  <FlexContainer justifyContent="center" alignItems="center">
                    <ImageResponsive
                      className="block sm-hidden"
                      lazy={true}
                      width={250}
                      src={InspectImage}
                      alt="inspect-img"/>
                  </FlexContainer>
                  <Paragraph className="mt-3">Get opportunities to improve the performance, accessibility,
                    best<br/> practices and SEO of your sites and services.</Paragraph>

                  <div className="flex mt-5 -mx-2">
                    <InsightCard>
                      <FlexContainer>
                        <div className="w-2/12">
                          <SvgBulb/>
                        </div>
                        <div className="w-10/12">
                          <Paragraph className="font-bold">Average site load times</Paragraph>
                        </div>
                      </FlexContainer>
                      <FlexContainer>
                        <div className="ml-ratio w-10/12">
                          <SubTitle>1.34s</SubTitle>
                        </div>
                      </FlexContainer>
                    </InsightCard>
                    <InsightCard>
                      <FlexContainer>
                        <div className="w-2/12">
                          <SvgBulb/>
                        </div>
                        <div className="w-10/12">
                          <Paragraph><span className="font-bold">Inspect</span> generates reports based
                            on the <span className="font-bold">top 5 visited pages</span> each month.</Paragraph>
                        </div>
                      </FlexContainer>
                    </InsightCard>
                  </div>

                  <FlexContainer justifyContent="center" alignItems="center" className="md-block mt-5">
                    <Button to={INSPECT_ROUTE} size="sm" variant={ButtonVariant.PrimaryOutlineWhite}>LEARN MORE</Button>
                  </FlexContainer>
                </div>
              </ServiceLayoutBlock>
              <ServiceLayout>
                <ImageResponsive
                  lazy={true}
                  width={450}
                  src={InspectImage}
                  alt="inspect-img"/>
              </ServiceLayout>
            </div>
          </LayoutContainer>
        </div>
      </Element>
      <Element name="section-wogaa-sentiments">
        <div className="my-20 xl-pt-32">
          <LayoutContainer className="px-4 pt-10" style={{maxWidth:1280}}>
            <div className="flex flex-wrap">
              <ServiceLayout>
                <ImageResponsive
                  lazy={true}
                  width={450}
                  src={SentimentsImage}
                  alt="sentiments-img"/>
              </ServiceLayout>
              <ServiceLayoutBlock>
                <div>
                  <Paragraph className="font-bold text-lg">Sentiments</Paragraph>
                  <SubTitle className="text-primary-dark mt-3">Curious how your users feel about your site?</SubTitle>
                  <FlexContainer justifyContent="center" alignItems="center">
                    <ImageResponsive
                      className="block sm-hidden"
                      lazy={true}
                      width={250}
                      src={SentimentsImage}
                      alt="sentiments-img"/>
                  </FlexContainer>
                  <Paragraph>Collect user satisfaction feedback and ratings across your sites and services.</Paragraph>
                  <div className="flex mt-5 -mx-2">
                    <InsightCard fullWidth={true}>
                      <FlexContainer>
                        <div className="w-1/12">
                          <SvgBulb/>
                        </div>
                        <div className="w-full lg-w-11/12 md-w-full">
                          <Paragraph><span className="font-bold">Sentiments</span> aligns with the <span
                            className="font-bold">Digital Government Blueprint</span>, <br/>allowing you to measure
                            digital satisfaction.</Paragraph>
                        </div>
                      </FlexContainer>
                    </InsightCard>
                  </div>

                  <FlexContainer justifyContent="center" alignItems="center" className="md-block mt-5">
                    <Button to={SENTIMENTS_ROUTE} size="sm" variant={ButtonVariant.PrimaryOutlineWhite}>LEARN
                      MORE</Button>
                  </FlexContainer>
                </div>
              </ServiceLayoutBlock>
            </div>
          </LayoutContainer>
        </div>
      </Element>
      <div className="xl-pt-10">
        <SignUpMountain title="Get started with WOGAA today." subTitle="Be data driven."/>
      </div>
    </section>
  );
};

export default OurServices;
