import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Spinner from './components/Spinner/Spinner';

import Home from './pages/Home';

export const ROOT_ROUTE = '/';
export const INSPECT_ROUTE = '/about/inspect';
export const SENTIMENTS_ROUTE = '/about/sentiments';
export const UPTIME_ROUTE = '/about/uptime';
export const WEB_ANALYTICS_ROUTE = '/about/web-analytics';
export const EVENTS_ROUTE = '/events';
export const FAQ_ROUTE = '/faq';
export const PRIVACY_STATEMENT_ROUTE = '/privacy-statement';

const EventsComponent = React.lazy(() => import('./pages/Events'));
const FaqComponent = React.lazy(() => import('./pages/Faq'));
const InspectComponent = React.lazy(() => import('./pages/Inspect'));
const SentimentsComponent = React.lazy(() => import('./pages/Sentiments'));
const UptimeComponent = React.lazy(() => import('./pages/Uptime'));
const WebAnalyticsComponent = React.lazy(() => import('./pages/WebAnalytics'));
const PrivacyStatementComponent = React.lazy(() => import('./pages/PrivacyStatement'));

export default function AppRouter() {
  return (
    <Routes>
      <Route path={INSPECT_ROUTE} element={
        <Suspense fallback={<Spinner />}>
          <ScrollToTop />
          <InspectComponent />
        </Suspense>
      } />
      <Route path={SENTIMENTS_ROUTE} element={
        <Suspense fallback={<Spinner />}>
          <ScrollToTop />
          <SentimentsComponent />
        </Suspense>
      } />
      <Route path={UPTIME_ROUTE} element={
        <Suspense fallback={<Spinner />}>
          <ScrollToTop />
          <UptimeComponent />
        </Suspense>
      } />
      <Route path={WEB_ANALYTICS_ROUTE} element={
        <Suspense fallback={<Spinner />}>
          <ScrollToTop />
          <WebAnalyticsComponent />
        </Suspense>
      } />
      <Route path={EVENTS_ROUTE} element={
        <Suspense fallback={<Spinner />}>
          <ScrollToTop />
          <EventsComponent />
        </Suspense>
      } />
      <Route path={FAQ_ROUTE} element={
        <Suspense fallback={<Spinner />}>
          <ScrollToTop />
          <FaqComponent />
        </Suspense>
      } />
      <Route path={PRIVACY_STATEMENT_ROUTE} element={
        <Suspense fallback={<Spinner />}>
          <ScrollToTop />
          <PrivacyStatementComponent />
        </Suspense>
      } />
      <Route path={ROOT_ROUTE} element={
        <>
          <ScrollToTop />
          <Home />
        </>
      } />
    </Routes>
  );
}
